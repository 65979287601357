@use "../../variables.scss" as variables;

.speechBubble {
    background-color: white;
    display: inline-flex;
    position: absolute;
    align-items: center;
    flex-direction: row;
    min-width: 100px;
    height: 95%;
    padding: 10px;    
    border-radius: 5px; 
    margin: 0 5px;
    div {        
        white-space: nowrap;
    }
    img {
        margin-right: 20px;
        margin-left: 10px;
        width: 20px;
    }
}
.right {

}

.left {
    right: 100%;
}

.banner {
}

.iconButton {
    filter: opacity(50%);
    border: solid 2px #ffa50050;
}

@media screen and(max-aspect-ratio: variables.$aspect-ratio) {
    .speechBubble {
        height: 90%;
        padding: 5px;
    }
    .right {
    }
    .left {
    }
    .banner {
    }
}
