@use "../../variables.scss" as variables;

.orientation_img {
    // position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.arrowButton {
    position: absolute;
    max-width: 20px;
    left: 80%;
    cursor: pointer;
    text-align: center;
    padding: 0;
    border: none;
    background: none;
}

.arrowButton:focus-visible {
    outline: auto;
}

.arrowButton:disabled {
    opacity: .3;
    filter: grayscale(0.5);
    cursor: default;
}

.nodeList {
    list-style-type: none;
    margin-top: 0px;
}

.skinButton {
    padding: 10px;
    width: 150px;
}

.horizontalGrid {
    display: grid;
    grid-template-areas:
        "top top"
        "left right"
        "bottom bottom";
    grid-template-columns: 1fr 1fr;
}

.settings {
    overflow-x: hidden;
    position: fixed;
    left: 1em;
    right: 1em;
    bottom: 1em;
    top: 1em;
    padding: 0 1.5em 1.5em 1.5em;
    border-radius: 1em;
    background: #f6d635;
    border: 8px solid #f67535;
    border-radius: 5px;
    z-index: 2;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: orange;
    padding-top: 15px;
    margin: -1.5em;
    padding: 1em;
}


.nodeRow {
    display: grid;
    grid-template-columns: 1fr 1fr 6fr;
    grid-template-areas: "icon checkbox label";
    margin: 5px;
}

.nodeCheckbox {
    grid-area: checkbox;
    justify-self: start;
}

.nodeLabel {
    grid-area: label;
    justify-self: start;
}

.nodeLabelBold {
    font-weight: bold;
    grid-area: label;
    justify-self: start;
}

.gemIcon {
    grid-area: icon;
    height: 20px;
    justify-self: start;
}

.gemRulesModal {
    overflow-x: hidden;
    position: fixed;
    left: 20em;
    right: 20em;
    bottom: 10em;
    top: 10em;
    padding: 0.5em 1.5em 1.5em 1.5em;
    border-radius: 1em;
    background: #f6d635;
    border: 8px solid #f67535;
    border-radius: 5px;
    z-index: 2;

    display: grid;
    grid-template-areas: "top top top" "left center right" "bottom bottom bottom";
    grid-template-rows: auto 1fr auto;

}

.gemRulesModalContent {
    grid-area: content;
}

.gemModalTitle {
    grid-area: top;
}

.gemRulesModalContent {
    grid-area: left;
}

.gemRulesCenter {
    grid-area: center;
}

.gemRulesRight {
    grid-area: right;
}

.gemRow {
          margin-top: 20px;  
}

.closeButton {
    grid-area: top;
    justify-self: flex-end;
    margin-right: -2%;
    cursor: pointer;
    max-width: 20px;
}

@media screen and (min-width: variables.$width-breakpoint) {
    .desktop {
        display: block;
        position: absolute;
        top: 5%;
        left: 10%;
        right: 10%;
        bottom: 5%;
    }

    .horizontalMobile {
        display: none;
    }

    .verticleMobile {
        display: none;
    }

    .settings {
        // top: 1em;
        // bottom: 1em;
        // max-width: 100%;
        // max-height: 100%;
    }

    .body {
        margin-top: 60px;
    }
}

@media screen and (max-width: variables.$width-breakpoint) {
    .desktop {
        display: none;
    }

    @media screen and(min-aspect-ratio: variables.$aspect-ratio) {
        .horizontalMobile {
            display: block;
        }

        .verticleMobile {
            display: none;
        }

        .settings {
            // position: fixed;
            // height: 100%;
            // padding: 0px;

            .body {
                padding: 1.5em;
                margin-top: 50px;
            }
        }

        .sticky {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
        }

        .arrowButton {
            top: 30%;
            right: 5%;
            left: auto;
        }

        .gemRulesModal {
            left: 3em;
            right: 3em;
            bottom: 2em;
            top: 2em;
        }
    }

    @media screen and(max-aspect-ratio: variables.$aspect-ratio) {
        .horizontalMobile {
            display: none;
        }

        .verticleMobile {
            display: block;
        }

        .settings {
            // position: fixed;
            // min-height: 68.47vw;
            // height: 100%;
            // top: 0;
            // padding: 0px;

            .body {
                padding: 1.5em;
                margin-top: 50px;
            }
        }

        .sticky {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            padding-top: 5px;
            padding-left: 1.5em;
        }

        .arrowButton {
            top: 30%;
            right: 5%;
            left: auto;
        }

        .skinButton {
            padding: 10px;
            width: 100px;
            height: 70px;
            white-space: normal;
        }

        .gemRulesModal {
            left: 2em;
            right: 2em;
            bottom: 2em;
            top: 2em;
            grid-template-areas: "top" "left" "center" "right" "bottom";
            grid-template-rows: auto auto auto auto 1fr;
        }
    }
}